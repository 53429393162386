import React from "react"

import { Link } from "gatsby"

import Layoutde from "../../components/layoutde/layoutde"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faRocket, faHeart, faSmile, faUserAstronaut, faCodeBranch, faFighterJet } from '@fortawesome/free-solid-svg-icons'

import Techstack from "../../components/techstack/techstack"

import "../index.scss"
import "../careers.scss"
import "../contact.scss"

import VideoBg from "../../videos/ftrs-about-us.mp4"

const Karriere = () => (

    <Layoutde>
        <SEO title="Karriere" description="Auf unserem Wachstumsweg freuen wir uns immer auf neue Stars in unserem Team. Lass uns über die Möglichkeiten einer Zusammenarbeit reden :-)" />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content pb-4">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Komm zu uns und&nbsp;werde ein&nbsp;Teil von&nbsp;Future&nbsp;Rockstars!</h1>

            <Row>

                <Col xs="12" lg="7" className="ftrs-career pb-3">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt-4 mb-5"><span role="img" aria-label="flash">⚡</span> PHP/Nette developer <span role="img" aria-label="rocket">🚀</span><span className="ftrs-career__hot">HOT</span></h2>
                    
                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3"><span role="img" aria-label="info">ℹ️</span> Wer wir sind und&nbsp;was wir tun:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Ein Entwicklerteam, das nicht erst seit gestern eigene <Link to="../referenzen">Web- und&nbsp;mobile Apps</Link> entwickelt.</p>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Unsere Apps begeistern führende Unternehmen, hauptsächlich aus&nbsp;der&nbsp;Automobilbranche und&nbsp;dem&nbsp;Banken-, Telekommunikations- und&nbsp;Versicherungsbereich (u.&nbsp;a.&nbsp;Vodafone, T-Mobile, O2, Airbank, Česká&nbsp;spořitelna, Uniqa, Toyota, Harley-Davidson).</p>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="superman">🦸‍♂️</span> Hilf uns bei der&nbsp;Erweiterung unseres Teams:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Für diese Position suchen wir jemanden mit&nbsp;einigen Jahren nachweislicher Erfahrung. Zur&nbsp;Arbeit an&nbsp;den&nbsp;Apps gehört auch die&nbsp;Unterstützung junger Kolleg*innen (etwa&nbsp;Code-Review, Peer-Training&nbsp;usw.).</p>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="cleverman">🤓</span> Womit du schon Kontakt gehabt haben und&nbsp;gut vertraut sein solltest:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>PHP</li>
                        <li>Nette</li>
                        <li>Mysql</li>
                        <li>Git</li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="rocket">🚀</span> Genial wäre, wenn&nbsp;du auch hiermit schon Erfahrung hättest:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>Docker</li>
                        <li>Elasticsearch</li>
                        <li>DevOps (AWS, Kubernetes)</li>
                        <li>Angular</li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="sparkling">✨</span> Das erwartet dich bei&nbsp;uns:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>Interessante Projekte für&nbsp;noch interessantere Kunden</li>
                        <li>Laufende Weiterbildungsevents</li>
                        <li>Angenehmes Arbeitsklima in&nbsp;einem klimatisierten Büro mit&nbsp;Terrasse.</li>
                        <li>Homeoffice – ständig oder teilweise, ganz wie es dir passt ... oder ggf. vom&nbsp;Gesetzgeber vorgegeben.</li>
                        <li>Dein Verdienst richtet sich nach&nbsp;dem&nbsp;Wissen, dem&nbsp;Können und&nbsp;den&nbsp;Erfahrungen, die&nbsp;du mitbringst, und&nbsp;natürlich auch nach&nbsp;deinen Leistungen.</li>
                        <li>Ein stabiles und&nbsp;funktionierendes Unternehmen mit&nbsp;interessanten Anreizen (Firmenevents, Offsites, Schulungen, Benefits&nbsp;usw.).</li>
                        <li>Eigene Firmenjacht am&nbsp;Slapy-Stausee für&nbsp;unsere Teammitglieder. <span role="img" aria-label="boat">⛵</span></li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="thinking">🤔</span> Welche Apps du bei&nbsp;uns entwickeln wirst?</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Die besten, die&nbsp;der&nbsp;Markt zur&nbsp;Verwaltung von&nbsp;Marketing-Marktforschungsprojekten für&nbsp;Mystery Shopping, Audit, Mitarbeitergewinnung, Vergütung, Datenerfassung und&nbsp;-kontrolle sowie Berichterstattung zu&nbsp;bieten hat. Die&nbsp;Kommunikation mit&nbsp;mobilen Apps erfolgt per&nbsp;API. Aktuell erwartet uns das&nbsp;große eigenständige Modul „Vergütung“.</p>
                </Col>

                <Col xs="12" lg="5" className="mt-3 pb-3">
                    <div className="ftrs-career__sticky">
                        <blockquote data-sal="fade" data-sal-duration="1000" className="ftrs-contact__box ftrs-contact__box--careers">
                            Wir freuen uns immer über&nbsp;neue Rockstars in&nbsp;unserem Team.<br />Wenn du dich angesprochen fühlst, freut sich Jaroslav auf&nbsp;deinen&nbsp;CV. <span role="img" aria-label="pointing down">👇</span>
                        </blockquote>
                        <div data-sal="fade" data-sal-duration="1000" className="ftrs-contact__box ftrs-contact__box--careers2">
							<p className="ftrs-contact__name">
                                Jaroslav Suchý
                            </p>
                            <p className="ftrs-contact__email">
                                <a href="mailto:jarda@futurerockstars.cz"><FontAwesomeIcon icon={faEnvelope} /> jarda@futurerockstars.cz</a><br />
                            </p>
                            <p className="ftrs-contact__phone">
                                <a href="tel:+420721765529"><FontAwesomeIcon icon={faPhone} /> +420 721 765 529</a>
                            </p>
                        </div>
                    </div>
                </Col>

            </Row>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt-5 mb-5">Warum Future Rockstars?</p>

            <Row>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faRocket} className="ftrs-career__icon ftrs-career__icon--color1" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Entwicklung</h3>
                            <p className="text-basic">Wir lernen gerne und&nbsp;greifen stetig nach&nbsp;den&nbsp;Sternen, indem wir neue Technologien erforschen und&nbsp;anwenden.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faHeart} className="ftrs-career__icon ftrs-career__icon--color2" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Flexibilität</h3>
                            <p className="text-basic">In Teams unserer Größe lassen sich Beziehungen und&nbsp;Prozesse an&nbsp;nahezu jeden Bedarf anpassen.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faFighterJet} className="ftrs-career__icon ftrs-career__icon--color3" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Transaktionen und Erfolge</h3>
                            <p className="text-basic">Wir arbeiten und&nbsp;liefern laufend an&nbsp;Unternehmen wie&nbsp;Audi, Toyota, T-Mobile, Airbank u.v.a.m.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faSmile} className="ftrs-career__icon ftrs-career__icon--color4" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Du kannst etwas bewegen.</h3>
                            <p className="text-basic">Jeden Monat interagieren rund 100.000 Nutzer mit&nbsp;unseren Produkten.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faUserAstronaut} className="ftrs-career__icon ftrs-career__icon--color5" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Ein starker Hintergrund</h3>
                            <p className="text-basic">Future Rockstars gehört zu&nbsp;einer starken Unternehmensholding mit&nbsp;mehr als&nbsp;15&nbsp;Jahren Erfahrung in&nbsp;Marktbeobachtung und&nbsp;-analyse sowie Kundenerfahrung.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faCodeBranch} className="ftrs-career__icon ftrs-career__icon--color6" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Wir teilen unser Wissen.</h3>
                            <p className="text-basic">Da wir unser Wissen gerne teilen, findet man uns bei&nbsp;zahlreichen Webinaren und&nbsp;unsere Codes bei&nbsp;GitHub. Zudem sind wir stolze <a href="https://nette.org/cs/donate" target="_blank" rel="noreferrer">Bronzepartner von&nbsp;PHP Nette</a>.</p>
                        </div>
                    </div>
                </Col>

            </Row>

        </Container>

        <Techstack lang='de'></Techstack>

    </Layoutde>

)

export default Karriere